'use client';

import styles from './index.module.css';

import { Toast, ToastDescription, ToastProvider, ToastViewport } from '@/components/ui/toast';
import { useToast } from '@/components/ui/use-toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider swipeDirection="down" duration={1500}>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast
            key={id}
            {...props}
            className={`fixed bottom-190px w-fit px-24px py-15px bg-[rgba(0,0,0.95)] text-14px leading-20px !rounded-28px border-none ${styles.ToastRoot}`}
          >
            <div className="grid gap-1 text-[#fff]">
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            {/* <ToastClose /> */}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
